<template>
  <b-card v-if="!loads">
    <b-row>
      <b-col md="3">
        <b-form-group label="Group Area">
          <model-select @input="chain_select('get_filter_customer_area')" :options="dropdown_customer_group_area" v-model="filter.group_area" placeholder="-- Please select --"></model-select>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Customer Area">
          <model-select @input="chain_select('get_filter_customer_group')" :options="dropdown_customer_area" v-model="filter.code_area" placeholder="-- Please select --"></model-select>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Customer Group">
          <model-select @input="chain_select('get_filter_customer_type')" :options="dropdown_customer_group" v-model="filter.code_group" placeholder="-- Please select --"></model-select>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Customer Type">
          <model-select :options="dropdown_customer_type" v-model="filter.code_type" placeholder="-- Please select --"></model-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="3">
        <b-button type="button" size="sm" variant="danger" @click="resetFilter"><i class="fa fa-undo"></i> Clear</b-button>
      </b-col>
    </b-row>
  </b-card>

  <b-card v-else>
    <loading></loading>
  </b-card>
</template>

<script>
import Loading from "@core/loading/Loading.vue";
import axios from "axios";

export default {
  name: "location-filter",
  components: {
    Loading,
  },
  data() {
    return {
      dropdown_customer_group_area: [],
      dropdown_customer_area: [],
      dropdown_customer_group: [],
      dropdown_customer_type: [],
    };
  },
  props: {
    filter: {
      type: [Array, Object],
      default: () => {},
    },
    loads: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    init: function () {
      //this.getDropdown();
      this.get_customer_group_area();
    },
    getDropdown: function () {
      axios.get("master/product/dropdown").then((response) => {
        this.dropdown_filter_area = response.data.customer_area;
      });
    },
    get_customer_group_area: function () {
      axios.get("dropdown/customer-group-area").then((response) => {
        this.dropdown_customer_group_area = response.data.data;
      }).catch((error) => {

      }).finally(() => {

      });
    },
    chain_select: function(val) {
      switch (val) {
        case 'get_filter_customer_area':
            this.dropdown_customer_area = [];
            this.filter.code_area = null;
            this.dropdown_customer_group = [];
            this.filter.code_group = null;
            this.dropdown_customer_type = [];
            this.filter.code_type = null;
            axios.get("dropdown/customer-area/"+this.filter.group_area).then((response) => {
              this.dropdown_customer_area = response.data.data;
            }).catch((error) => {

            }).finally(() => {

            });
          break;
        
        case 'get_filter_customer_group':
            this.dropdown_customer_group = [];
            this.filter.code_group = null;
            this.dropdown_customer_type = [];
            this.filter.code_type = null;
            axios.get("dropdown/customer-group-type").then((response) => {
              this.dropdown_customer_group = response.data.data;
            }).catch((error) => {

            }).finally(() => {

            });
          break;
        
        case 'get_filter_customer_type':
            this.dropdown_customer_type = [];
            this.filter.code_type = null;
            axios.get("dropdown/customer-type/"+this.filter.code_group).then((response) => {
              this.dropdown_customer_type = response.data.data;
            }).catch((error) => {

            }).finally(() => {

            });
          break;
      
        default:
          break;
      }
    },
    resetFilter: function () {
      this.filter.group_area = null;
      this.filter.code_area = null;
      this.filter.code_group = null;
      this.dropdown_customer_area = [];
      this.dropdown_customer_group = [];
      this.dropdown_customer_type = [];
      this.$root.$emit('bv::refresh::table', 'myTable');
    },
  },
  mounted() {
    this.init();
  },
};
</script>