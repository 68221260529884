<template>
  <b-card v-if="!loads">
    <b-row>
      <b-col md="3">
        <b-form-group label="Location">
          <model-select :options="dropdown_filter_location" v-model="filter.show_location" placeholder="-- Please select --"></model-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-button type="button" size="sm" variant="danger" @click="resetFilter"
          ><i class="fa fa-undo"></i> Clear</b-button
        >
      </b-col>
    </b-row>
  </b-card>

  <b-card v-else>
    <loading></loading>
  </b-card>
</template>

<script>
import Loading from "@core/loading/Loading.vue";
import axios from "axios";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "questioner-filter",
  components: {
    Loading,
    flatPickr,
  },
  data() {
    return {
      dropdown_filter_location: [
        {"text":"Self Register","value":"1"},
        {"text":"FAB Form","value":"2"}
      ],
    };
  },
  props: {
    filter: {
      type: [Array, Object],
      default: () => {},
    },
    loads: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    init: function () {
          this.resetFilter();
    },
    resetFilter: function () {
      this.filter.show_location = null;
      this.$root.$emit('bv::refresh::table', 'myTable');
    },
  },
  mounted() {
    this.init();
  },
};
</script>