<template>
  <b-card v-if="!loads">
    <b-row>
      <b-col md="3">
        <b-form-group label="Status">
          <model-select :options="dropdown_filter_status" v-model="filter.sales_status" placeholder="-- Please select --"></model-select>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Date Inserted">
         <flat-pickr class="form-control" v-model="filter.date" placeholder="Date Inserted"/>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Customer Area">
          <model-select :options="dropdown_customer_area" v-model="filter.code_area" placeholder="-- Please select --"></model-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="3">
        <b-button type="button" size="sm" variant="danger" @click="resetFilter"><i class="fa fa-undo"></i> Clear</b-button>
      </b-col>
    </b-row>
  </b-card>

  <b-card v-else>
    <loading></loading>
  </b-card>
</template>

<script>
import Loading from "@core/loading/Loading.vue";
import axios from "axios";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "sales-order-filter",
  components: {
    Loading,
    flatPickr,
  },
  data() {
    return {
      dropdown_filter_status: [],
      dropdown_customer_area: [],
    };
  },
  props: {
    filter: {
      type: [Array, Object],
      default: () => {},
    },
    loads: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    init: function () {
      this.get_status();
      this.get_customer_area();
    },
    get_status: function () {
      axios.get("dropdown/sales-order-status").then((response) => {
        this.dropdown_filter_status = response.data.data;
      }).catch((error) => {}).finally(() => {});
    },
    get_customer_area: function () {
      axios.get("dropdown/customer_area").then((response) => {
        this.dropdown_customer_area = response.data.data;
      }).catch((error) => {}).finally(() => {});
    },
    resetFilter: function () {
      this.filter.sales_status = null;
      this.filter.date = null;
      this.filter.code_area = null;
      this.$root.$emit('bv::refresh::table', 'myTable');
    },
  },
  mounted() {
    this.init();
  },
};
</script>