<template>
  <b-card v-if="!loads">
    <b-row>
      <b-col md="3">
        <b-form-group label="Customer">
          <vue-autosuggest
              v-model="filter.customer_name" 
              :suggestions="filteredOptions"
              @input="onInputChange" 
              @selected="onSelected"
              :input-props="{id:'customer', placeholder:'Customer Name', class:'form-control'}"
              :limit="10">
              <template slot-scope="{suggestion}">
                <span class="my-suggestion-item">
                  {{ suggestion.item.customer_code }}<br>
                  {{ suggestion.item.name }}<br>
                  {{ suggestion.item.customer_area_name }} &nbsp; 
                  {{ suggestion.item.location_name }}</span>
              </template>                  
          </vue-autosuggest>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="3">
        <b-button type="button" size="sm" variant="danger" @click="resetFilter"><i class="fa fa-undo"></i> Clear</b-button>
      </b-col>
    </b-row>
  </b-card>

  <b-card v-else>
    <loading></loading>
  </b-card>
</template>

<script>
import Loading from "@core/loading/Loading.vue";
import axios from "axios";
// import flatPickr from "vue-flatpickr-component";
import { VueAutosuggest } from 'vue-autosuggest';

export default {
  name: "contact-filter",
  components: {
    Loading,
    // flatPickr,
    VueAutosuggest
  },
  data() {
    return {
      dropdown_customer: [],
      filteredOptions: [],
      selected: "",
      suggestions: [{
          data: []
      }],
    };
  },
  props: {
    filter: {
      type: [Array, Object],
      default: () => {},
    },
    loads: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    init: function () {
    },
    onInputChange(text) {      
      if (text === '' || text === undefined || text === null) {
        return
      } else {
        axios.get("dropdown/customer_autosuggest/"+text).then((response) => {
          this.dropdown_customer = response.data.data;
          this.suggestions[0].data = this.dropdown_customer; 
          const filteredData = this.suggestions[0].data
          this.filteredOptions = [{
            data: filteredData,
          }]
        }).catch((error) => {}).finally(() => {});
      }
      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      // const filteredData1 = this.suggestions[0].data.filter(item => 
      // console.log(item.text)
      //   item.customer_code.toLowerCase().indexOf(text.toLowerCase()) > -1
      // ).slice(0, this.limit)
      // const filteredData2 = this.suggestions[0].data.filter(item => 
      //   item.name.toLowerCase().indexOf(text.toLowerCase()) > -1
      // ).slice(0, this.limit)
      // const filteredData = filteredData1.concat(filteredData2)
      // this.filteredOptions = [{
      //   data: filteredData,
      // }]
    },
    onSelected(item) {
      if (item === '' || item === undefined || item === null) {
        return
      } else {
        this.selected = item.item;
        this.filter.customer_code = item.item.customer_code;
        this.filter.customer_name = item.item.name;
      }      
    },
    resetFilter: function () {
      this.filter.customer_name = null;
      this.$root.$emit('bv::refresh::table', 'myTable');
    },
  },
  mounted() {
    this.init();
  },
};
</script>