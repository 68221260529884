<template>
  <b-card v-if="!loads">
    <b-row>
      <b-col md="3">
        <b-form-group label="Area">
          <model-select :options="dropdown_show_area" v-model="filter.area" placeholder="-- Please select --"></model-select>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Role">
          <model-select :options="dropdown_position" v-model="filter.role" placeholder="-- Please select --"></model-select>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Year">
          <model-select :options="dropdown_year" v-model="filter.year" placeholder="-- Please select --"></model-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-button type="button" size="sm" variant="danger" @click="resetFilter"
          ><i class="fa fa-undo"></i> Clear</b-button
        >
      </b-col>
    </b-row>
  </b-card>

  <b-card v-else>
    <loading></loading>
  </b-card>
</template>

<script>
import Loading from "@core/loading/Loading.vue";
import axios from "axios";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "target-filter",
  components: {
    Loading,
    flatPickr,
  },
  data() {
    return {
      dropdown_show_area: [
        {"text":"Area 1","value":"1"},
        {"text":"Area 2","value":"2"},
        {"text":"Area 3","value":"3"},
        {"text":"Area 4","value":"4"},
        {"text":"Area 5","value":"5"},
        {"text":"Corporate","value":"6"}
      ],
      dropdown_position: [
        {"text":"GM","value":"1"},
        {"text":"RSM","value":"2"},
        {"text":"CM","value":"3"},
        {"text":"AM","value":"4"},
        {"text":"AO","value":"5"},
      ],
      dropdown_year: []
    };
  },
  props: {
    filter: {
      type: [Array, Object],
      default: () => {},
    },
    loads: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    init: function () {
      this.get_year();    
      this.resetFilter();
    },
    resetFilter: function () {
      this.filter.area = null;
      this.filter.role = null;
      this.filter.year = null;
      this.$root.$emit('bv::refresh::table', 'myTable');
    },
    get_year: function () {
      axios.get("master/target_scheme/yearList").then((response) => {
        this.dropdown_year = response.data.dropdown_year_list;
      }).catch((error) => {

      }).finally(() => {

      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>