<template>
  <div>
    <invoice-filter v-if="filterOps === 'invoice'" :filter="filter" :loads="loading"></invoice-filter>
    <customer-filter v-if="filterOps === 'customer'" :filter="filter" :loads="loading"></customer-filter>
    <location-filter v-if="filterOps === 'location'" :filter="filter" :loads="loading"></location-filter>
    <promotion-filter v-if="filterOps === 'promotion'" :filter="filter" :loads="loading"></promotion-filter>
    <sales-order-filter v-if="filterOps === 'sales-order'" :filter="filter" :loads="loading"></sales-order-filter>
    <price-catalog-filter v-if="filterOps === 'price-catalog'" :filter="filter" :loads="loading"></price-catalog-filter>
    <product-filter v-if="filterOps === 'product'" :filter="filter" :loads="loading"></product-filter>
    <contact-filter v-if="filterOps === 'contact'" :filter="filter" :loads="loading"></contact-filter>
    <questioner-filter v-if="filterOps === 'questioner'" :filter="filter" :loads="loading"></questioner-filter>
    <target-filter v-if="filterOps === 'target-scheme'" :filter="filter" :loads="loading"></target-filter>
    <self-registration-filter v-if="filterOps === 'self-registration'" :filter="filter" :loads="loading"></self-registration-filter>
    
    
    <b-card>
      <b-row v-if="!loading">
        <b-col md="12">
          <b-button size="sm" v-if="showAddOnButton" v-on:click.prevent="addOnForm($event.target)" variant="primary">
            <feather-icon icon="PlusSquareIcon" class="mr-50" />
            <span>Add Contract Add On</span>
          </b-button>
          &nbsp;
          <b-button size="sm" v-if="showOneTimeFeeCustomButton" v-on:click.prevent="addOneTimeFeeForm($event.target)" variant="primary">
            <feather-icon icon="PlusSquareIcon" class="mr-50" />
            <span>Add One Time Fee Custom</span>
          </b-button>
          &nbsp;
          <b-button size="sm" v-if="showAddButton" v-on:click.prevent="addForm($event.target)" variant="primary">
            <feather-icon icon="PlusSquareIcon" class="mr-50" />
            <span>{{ addButton }}</span>
          </b-button>
          &nbsp;
          <b-button size="sm" v-if="showImportButton" v-on:click.prevent="importForm($event.target)" variant="warning">
            <feather-icon icon="PlusSquareIcon" class="mr-50" />
            <span>{{ importButton }}</span>
          </b-button>
          &nbsp;
          <b-button size="sm" v-if="showExportButton" v-on:click.prevent="exportForm($event.target)" variant="warning">
            <feather-icon icon="PlusSquareIcon" class="mr-50" />
            <span>{{ exportButton }}</span>
          </b-button>
        </b-col>

        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0" v-if="filterOptions">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select id="perPageSelect" size="sm" v-model="perPage" :options="pageOptions" class="w-50"/>
          </b-form-group>
        </b-col>

        <!-- <b-col md="4" sm="8" class="my-1">&nbsp;</b-col> -->
        <b-col md="4" class="my-1">
          <b-form-group v-if="filterOptions" label="Filter" label-cols-sm="2" label-align-sm="left" label-size="sm" label-for="filterInput" class="mb-0">
            <b-input-group col="2" size="sm">
              <b-form-input id="filterInput" v-model="filter.search" type="search" debounce="1000" placeholder="Type to Search"/>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = {}"> Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col v-if="updateReadynessAll" md="12" class="my-1">
          <b-form-group >
           <b-form-checkbox
              ref="selection"
              id="selection"
              @change="selectAllRows"
              name="selection"
              value="select-all"
              unchecked-value="remove-select-all"
            >
            {{(infoSelection === 0) ? 'Select All' : infoSelection+' Selected'}}
            </b-form-checkbox>
          </b-form-group>
            
            <b-button type="button" size="sm" variant="success" @click="updateAllSelectedReady" :disabled="(infoSelection === 0) ? true: false">
              <i class="fa fa-undo"></i> {{(infoSelection === 0) ? 'Update readyness to Ready' : infoSelection+' items update to Ready'}}
            </b-button>&nbsp;
            <b-button type="button" size="sm" variant="warning" @click="updateAllSelectedNotReady" :disabled="(infoSelection === 0) ? true: false">
              <i class="fa fa-undo"></i> {{(infoSelection === 0) ? 'Update readyness to Not Ready' : infoSelection+' items update to Not Ready'}}
            </b-button>

            <br/>
        </b-col>

        <b-col cols="12">
          <b-table ref="myTable" striped selectable hover responsive :per-page="perPage" :current-page="currentPage" :items="tableData" :fields="fields" :filter="filter" :show-empty="true" :redirect-detail="redirectDetail" @forceRefresh="forceRefresh" @row-selected="onRowSelected">
            
            <template #table-caption>&nbsp;</template>

            <template #table-busy>
              <loading></loading>
            </template>

            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <label class="sr-only">Selected</label>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>
            
            <template #cell(period_date)="data">
                <span>{{formatDateHumanRead(data.item.start_date)}}</span>&nbsp;
                <span v-if="data.item.end_date !== null "> - {{formatDateHumanRead(data.item.end_date)}}</span>
            </template>

            <template #cell(start_time)="data">
              <span>{{ dateTimeContractFormat(data.item.start_time) }}</span>
            </template>
             
            <template #cell(end_time)="data">
              <span>{{ dateTimeContractFormat(data.item.end_time) }}</span>
            </template>

            <template #cell(start_date_contract)="data">
              <span>{{ dateTimeContractFormat(data.item.start_date_contract) }}</span>
            </template>
             
            <template #cell(end_date_contract)="data">
              <span>{{ dateTimeContractFormat(data.item.end_date_contract) }}</span>
            </template>

            <template #cell(invoice_date)="data">
              <span>{{ formatDateHumanRead(data.item.invoice_date) }}</span>
            </template>

            <template #cell(due_date)="data">
              <span>{{ formatDateHumanRead(data.item.due_date) }}</span>
            </template>

            <template #cell(start_date)="data">
              <span>{{ formatDateHumanRead(data.item.start_date) }}</span>
            </template>

            <template #cell(date_insert)="data">
              <span>{{ formatDateHumanRead(data.item.date_insert) }}</span>
            </template>

            <template #cell(date_inserted)="data">
              <span>{{ dateTimeFormat(data.item.date_inserted) }}</span>
            </template>

            <template #cell(date_activated)="data">
              <span>{{ dateTimeFormat(data.item.date_activated) }}</span>
            </template>

            <template #cell(tnc_bahasa)="data">
              <span v-html="data.item.tnc_bahasa"></span>
            </template>

            <template #cell(tnc_english)="data">
              <span v-html="data.item.tnc_english"></span>
            </template>

            <template #cell(pdp_bahasa)="data">
              <span v-html="data.item.pdp_bahasa"></span>
            </template>

            <template #cell(pdp_english)="data">
              <span v-html="data.item.pdp_english"></span>
            </template>

            <template #cell(price)="data">
              <span>Rp. {{ formatPrice(data.item.price) }}</span>
            </template>

            <template #cell(price_exclude_tax)="data">
              <span>Rp. {{ formatPrice(data.item.price_exclude_tax) }}</span>
            </template>

            <template #cell(price_include_tax)="data">
              <span>Rp. {{ formatPrice(data.item.price_include_tax) }}</span>
            </template>

            <template #cell(total_price_include_tax)="data">
              <span>Rp. {{ formatPrice(data.item.total_price_include_tax) }}</span>
            </template>

            <template #cell(total_price_exclude_tax)="data">
              <span>Rp. {{ formatPrice(data.item.total_price_exclude_tax) }}</span>
            </template>
            
            <template #cell(discount_price)="data">
            <span>{{(data.item.discount_price != "" && data.item.discount_price != null) ? 'Rp. ' : ''}} {{ formatPrice(data.item.discount_price) }}</span>
            </template>

            <template #cell(total_calculation)="data">
            <span>{{(data.item.total_calculation != "" && data.item.total_calculation != null) ? 'Rp. ' : ''}} {{ formatPrice(data.item.total_calculation) }}</span>
            </template>

            <template #cell(total)="data">
              <span>Rp. {{ formatPrice(data.item.total) }}</span>
            </template>

            <template #cell(end_date)="data">
              <span>{{ formatDateHumanRead(data.item.end_date) }}</span>
            </template>

            <template #cell(product_category_name)="data">
              <div>
                <div>{{data.item.product_name}}</div>
                <b-badge variant="success"><span>{{data.item.product_category_name}}</span></b-badge>
              </div>
            </template>

            <template #cell(product_invoice_name)="data">
              <div>
                <div>{{data.item.product_invoice_name}}</div>
                <b-badge variant="success"><span>{{data.item.product_category_name}}</span></b-badge>
              </div>
            </template>

            <template #cell(product_charge_log_name)="data">
              <div>
                <div>{{data.item.product_name}}</div>
                <b-badge variant="success"><span>{{data.item.product_category_name}}</span></b-badge>
              </div>
            </template>

            <template #cell(start_time_contract_subscription)="data">
              <div>
              <div class="text-uppercase">
                <span>{{dateTimeContractFormat(data.item.start_time_contract_subscription)}}</span>&nbsp;
                <span class="text-uppercase"> - <b v-if="data.item.end_time_contract_subscription !== null"></b></span>
                <span class="text-uppercase"><b v-if="data.item.end_time_contract_subscription == null"><font-awesome-icon  icon="infinity"/></b></span>
                <span v-if="data.item.end_time_contract_subscription !== null " class="text-uppercase" v-html="dateTimeContractFormat(data.item.end_time_contract_subscription)"></span>
              </div><br>
              <div class="text-uppercase"> <b>Subscription Type :</b></div>
              <b-badge variant="success"><span>{{data.item.subscription_type_name}}</span></b-badge>
              </div>
            </template>

            <template #cell(target_month)="data">
              <table class='table borderless'>
                <tr>
                  <td><b>January</b></td>
                  <td><b>:</b></td>
                  <td><b-badge variant="success"><span>{{data.item.january}}</span></b-badge></td>
                  <td><b>February</b></td>
                  <td><b>:</b></td>
                  <td><b-badge variant="success"><span>{{data.item.february}}</span></b-badge></td>
                  <td><b>March</b></td>
                  <td><b>:</b></td>
                  <td><b-badge variant="success"><span>{{data.item.march}}</span></b-badge></td>
                </tr>
                <tr>
                  <td><b>April</b></td>
                  <td><b>:</b></td>
                  <td><b-badge variant="success"><span>{{data.item.april}}</span></b-badge></td>
                  <td><b>May</b></td>
                  <td><b>:</b></td>
                  <td><b-badge variant="success"><span>{{data.item.may}}</span></b-badge></td>
                  <td><b>June</b></td>
                  <td><b>:</b></td>
                  <td><b-badge variant="success"><span>{{data.item.june}}</span></b-badge></td>
                </tr>
                <tr>
                  <td><b>July</b></td>
                  <td><b>:</b></td>
                  <td><b-badge variant="success"><span>{{data.item.july}}</span></b-badge></td>
                  <td><b>August</b></td>
                  <td><b>:</b></td>
                  <td><b-badge variant="success"><span>{{data.item.august}}</span></b-badge></td>
                  <td><b>September</b></td>
                  <td><b>:</b></td>
                  <td><b-badge variant="success"><span>{{data.item.september}}</span></b-badge></td>
                </tr>
                <tr>
                  <td><b>October</b></td>
                  <td><b>:</b></td>
                  <td><b-badge variant="success"><span>{{data.item.october}}</span></b-badge></td>
                  <td><b>November</b></td>
                  <td><b>:</b></td>
                  <td><b-badge variant="success"><span>{{data.item.november}}</span></b-badge></td>
                  <td><b>December</b></td>
                  <td><b>:</b></td>
                  <td><b-badge variant="success"><span>{{data.item.december}}</span></b-badge></td>
                </tr>
              </table>
              </template>

            <template #cell(periode_charge_log)="data">
              <div>
              <div class="text-uppercase">
                <span>{{dateTimeContractFormat(data.item.start_time_charge_log)}}</span>&nbsp;
                <span class="text-uppercase"> - <b v-if="data.item.end_time_charge_log !== null"></b></span>
                <span class="text-uppercase"><b v-if="data.item.end_time_charge_log == null"><font-awesome-icon  icon="infinity"/></b></span>
                <span v-if="data.item.end_time_charge_log !== null " class="text-uppercase" v-html="dateTimeContractFormat(data.item.end_time_charge_log)"></span>
              </div><br>
              <div class="text-uppercase"> <b>Charge Log Status :</b></div>
              <b-badge variant="info"><span>{{data.item.charge_log_status_name}}</span></b-badge>
              </div>
            </template>
            
            <template #cell(action)="data">
              <div v-if="data.item.status!=1">
                  <div v-if="redirectDetail && editButtonRegistration"> 
                    <span v-if="data.item.flag==3">
                      <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                        <template v-slot:button-content>
                          <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25"/>
                        </template> 
                        <div v-if="data.item.status!=2">  
                          <b-dropdown-item v-if="redirectDetail && editButtonRegistration" @click="goToFab(data.item)">
                            <feather-icon icon="EditIcon" class="mr-50" />
                            <span>Add Fab</span>
                          </b-dropdown-item>
                        </div>
                        <div v-if="data.item.flag==3 && data.item.parent==1">
                          <b-dropdown-item v-if="data.item.flag==3" @click="customerAssign(data.item)">
                            <feather-icon icon="EditIcon" class="mr-50" />
                            <span>Assign to</span>
                          </b-dropdown-item>
                        </div>
                        <b-dropdown-item @click="getQuesioner(data.item)">
                          <feather-icon icon="EditIcon" class="mr-50" />
                          <span>View Quesioner</span>
                        </b-dropdown-item>
                        <b-dropdown-item  v-if="filterOps == 'self-registration'" @click="customerCancelSelfRegis(data.item)">
                          <feather-icon icon="EditIcon" class="mr-50" />
                          <span>Cancel Regis</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span> 
                  </div>
                  <div v-else>
                  <span>
                    <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25"/>
                      </template>      
                      <b-dropdown-item v-if="redirectDetail && editButton" @click="goToDetail(data.item)">
                        <feather-icon icon="EditIcon" class="mr-50" />
                        <span>Edit</span>
                      </b-dropdown-item>
                      <b-dropdown-item v-if="redirectDetail && editButton && duplicateAdd" @click="goToDuplicate(data.item)">
                        <feather-icon icon="EditIcon" class="mr-50" />
                        <span>Duplicate</span>
                      </b-dropdown-item>

                      <b-dropdown-item v-if="!redirectDetail && editButton" @click="editForm(data.item)">
                        <feather-icon icon="EditIcon" class="mr-50" />
                        <span>Edit</span>
                      </b-dropdown-item>

                      <b-dropdown-item v-if="!redirectDetail && addEquipment" @click="addmoreEquipment(data.item)">
                        <feather-icon icon="EditIcon" class="mr-50" />
                        <span>Add Equipment</span>
                      </b-dropdown-item>

                      <b-dropdown-item v-if="!redirectDetail && previewButton" @click="previewForm(data.item)">
                        <feather-icon icon="EditIcon" class="mr-50" />
                        <span>Preview</span>
                      </b-dropdown-item>

                      <b-dropdown-item v-if="activationButton && data.item.status == 5" @click="activationCustomer(data.item)">
                        <feather-icon icon="CheckIcon" class="mr-50" />
                        <span>Activation</span>
                      </b-dropdown-item>

                      <b-dropdown-item v-if="resendActivationEmailButton && data.item.activation_email != 1" @click="resendActivationEmailSalesOrder(data.item)">
                        <feather-icon icon="RefreshCcwIcon" class="mr-50" />
                        <span>Resend Activation</span>
                      </b-dropdown-item>

                      <b-dropdown-item v-if="downloadInvoiceButton" @click="downloadInvoice(data.item)">
                        <feather-icon icon="DownloadIcon" class="mr-50" />
                        <span>Download Invoice</span>
                      </b-dropdown-item>

                      <b-dropdown-item v-if="removeButton && data.item.status!=2" @click="removeData(data.item)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Delete</span>
                      </b-dropdown-item>

                      <b-dropdown-item v-if="data.item.product_flag_charge_log==2" @click="removeChargeLog(data.item)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Delete Charge Log</span>
                      </b-dropdown-item>

                      <b-dropdown-item v-if="downloadButton" @click="downloadData(data.item)">
                        <feather-icon icon="DownloadIcon" class="mr-50" />
                        <span>Download</span>
                      </b-dropdown-item>

                      <b-dropdown-item v-if="!redirectDetail && editTermination" @click="editmoreTermination(data.item)">
                        <feather-icon icon="EditIcon" class="mr-50" />
                        <span>Termination</span>
                      </b-dropdown-item>

                      <div v-if="data.item.admin==1 || data.item.update_readyness==1">
                        <b-dropdown-item v-if="data.item.readyness==2" @click="editReadyness(data.item)">
                          <feather-icon icon="ClockIcon" class="mr-50" />
                          <span>No Ready</span>
                        </b-dropdown-item>

                        <b-dropdown-item v-if="data.item.readyness==30" @click="editReadyness(data.item)">
                          <feather-icon icon="CheckCircleIcon" class="mr-50" />
                          <span>Ready</span>
                        </b-dropdown-item>
                      </div>

                      <b-dropdown-item v-if="historyLogButton && data.item.admin==1" @click="getDataLog(data.item.location_address_code)">
                        <feather-icon icon="EyeIcon" class="mr-50" />
                        <span>History</span>
                      </b-dropdown-item>
                      

                    </b-dropdown>
                  </span>
                </div>
              </div>
              <div v-if="data.item.status==1">
                <span>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25"/>
                    </template>
                  
                    <div v-if="data.item.status!=2">  
                      <b-dropdown-item v-if="redirectDetail && editButtonRegistration" @click="goToFab(data.item)">
                        <feather-icon icon="EditIcon" class="mr-50" />
                        <span>Add Fab</span>
                      </b-dropdown-item>
                    </div>
                    
                    <div v-if="data.item.flag==3 && data.item.parent==1"> 
                      <span v-if="redirectDetail && editButtonRegistration">
                        <div>
                          <b-dropdown-item @click="customerAssign(data.item)">
                            <feather-icon icon="EditIcon" class="mr-50" />
                            <span>Assign to</span>
                          </b-dropdown-item>
                        </div>
                      </span>
                    </div>
                    
                    <b-dropdown-item v-if="redirectDetail && data.item.flag==3" @click="getQuesioner(data.item)">
                      <feather-icon icon="EditIcon" class="mr-50" />
                      <span>View Quesioner</span>
                    </b-dropdown-item>
                    
                    <b-dropdown-item  v-if="filterOps == 'self-registration'" @click="customerCancelSelfRegis(data.item)">
                      <feather-icon icon="EditIcon" class="mr-50" />
                      <span>Cancel Regis</span>
                    </b-dropdown-item>

                    <b-dropdown-item v-if="redirectDetail && editButton" @click="goToDetail(data.item)">
                      <feather-icon icon="EditIcon" class="mr-50" />
                      <span>Edit</span>
                    </b-dropdown-item>

                    <b-dropdown-item v-if="redirectDetail && editButton && duplicateAdd" @click="goToDuplicate(data.item)">
                      <feather-icon icon="EditIcon" class="mr-50" />
                      <span>Duplicate</span>
                    </b-dropdown-item>
                    
                    <b-dropdown-item v-if="!redirectDetail && editButton" @click="editForm(data.item)">
                      <feather-icon icon="EditIcon" class="mr-50" />
                      <span>Edit</span>
                    </b-dropdown-item>

                    <b-dropdown-item v-if="!redirectDetail && addEquipment" @click="addmoreEquipment(data.item)">
                      <feather-icon icon="EditIcon" class="mr-50" />
                      <span>Add Equipment</span>
                    </b-dropdown-item>

                    <b-dropdown-item v-if="!redirectDetail && previewButton" @click="previewForm(data.item)">
                      <feather-icon icon="EditIcon" class="mr-50" />
                      <span>Preview</span>
                    </b-dropdown-item>

                    <b-dropdown-item v-if="activationButton && data.item.status == 5" @click="activationCustomer(data.item)">
                      <feather-icon icon="CheckIcon" class="mr-50" />
                      <span>Activation</span>
                    </b-dropdown-item>

                    <b-dropdown-item v-if="resendActivationEmailButton && data.item.activation_email != 1" @click="resendActivationEmailSalesOrder(data.item)">
                      <feather-icon icon="RefreshCcwIcon" class="mr-50" />
                      <span>Resend Activation</span>
                    </b-dropdown-item>

                    <b-dropdown-item v-if="downloadInvoiceButton" @click="downloadInvoice(data.item)">
                      <feather-icon icon="DownloadIcon" class="mr-50" />
                      <span>Download Invoice</span>
                    </b-dropdown-item>

                    <b-dropdown-item v-if="removeButton" @click="removeData(data.item)">
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Delete</span>
                    </b-dropdown-item>
                        
                    <b-dropdown-item v-if="downloadButton" @click="downloadData(data.item)">
                      <feather-icon icon="DownloadIcon" class="mr-50" />
                      <span>Download</span>
                    </b-dropdown-item>

                    <b-dropdown-item v-if="!redirectDetail && editTermination" @click="editmoreTermination(data.item)">
                      <feather-icon icon="EditIcon" class="mr-50" />
                      <span>Termination</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </div>
            </template>
            <template #cell(status_desc)="data">
              <div>
                <span>
                  {{data.item.status_desc}}
                </span><br>
                <b-badge variant="success"><span v-if="data.item.flag == 2">Register by Sales</span></b-badge>
                <b-badge variant="success"><span v-if="data.item.flag == 3">Self Register</span></b-badge>
              </div>
            </template>
            <template #cell(start_time_charge_log)="data">
              <div>
                <div class="text-uppercase">
                  <span>{{dateTimeContractFormat(data.item.start_time_charge_log)}}</span>&nbsp;
                  <span class="text-uppercase"> - <b v-if="data.item.end_time_charge_log !== null"></b></span>
                  <span class="text-uppercase"><b v-if="data.item.end_time_charge_log == null"><font-awesome-icon  icon="infinity"/></b></span>
                  <span v-if="data.item.end_time_charge_log !== null " class="text-uppercase" v-html="dateTimeContractFormat(data.item.end_time_charge_log)"></span>
                </div><br>
                <div class="text-uppercase"> <b>Charge Log Status :</b></div>
                <b-badge variant="light-secondary"><span>{{data.item.charge_log_status_name}}</span></b-badge>
              </div>
            </template>
            <template #cell(email_self_registration)="data">
              <div>
                <span>
                  {{data.item.email}}
                </span><br>
                <b-badge variant="success"><span>{{data.item.area_name}}</span></b-badge>&nbsp;
                <b-badge variant="success"><span>{{data.item.group_name}}</span></b-badge>&nbsp;
                <b-badge variant="success"><span>{{data.item.cust_type_name}}</span></b-badge>&nbsp;
                <b-badge variant="success"><span>{{data.item.location_name}}</span></b-badge>
              </div>
            </template>
            <template #cell(information_customer_name)="data">
              <div>
                <span>
                  {{data.item.name}}
                </span><br>
                <b-badge variant="success"><span>{{data.item.customer_group_type_name}}</span></b-badge>&nbsp;
                <b-badge variant="success"><span>{{data.item.customer_type_name}}</span></b-badge>&nbsp;
                <b-badge variant="success"><span>{{data.item.customer_area_name}}</span></b-badge>
              </div>
            </template>
            <template #cell(access_menu_employee_name)="data">
              <div>
                <span>
                  {{data.item.name}}
                </span><br>
                <b-badge variant="success"><span>{{data.item.department_name}}</span></b-badge>&nbsp;
              </div>
            </template>
            <template #cell(information_sales_customer_name)="data">
              <div style="posistion:relative;width=350px;">
                <span><b>{{data.item.customer_code}}</b></span><br>
                <span>{{data.item.information_sales_customer_name}}</span><br>
                <span>{{data.item.customer_email}}</span><br>
                <b-badge variant="success"><span>{{data.item.customer_group_type_name}}</span></b-badge>&nbsp;
                <b-badge variant="success"><span>{{data.item.customer_type_name}}</span></b-badge>&nbsp;
                <b-badge variant="success"><span>{{data.item.customer_area_name}}</span></b-badge>
              </div>
            </template>
            <template #cell(customer_status)="data">
              <div style="posistion:relative;width=350px;">
                <span>{{data.item.employee_name}}</span><br>
                <b-badge variant="light-success"><span>{{data.item.customer_status_name}}</span></b-badge>&nbsp;
              </div>
            </template>
            <template #cell(information_contract)="data">
              <div style="posistion:relative;width=350px;">
                <span>{{data.item.customer_name}}</span><br>
                <b-badge variant="success"><span>{{data.item.customer_area_name}}</span></b-badge>&nbsp;
                <b-badge variant="success"><span>{{data.item.customer_group_type_name}}</span></b-badge>&nbsp;
                <b-badge variant="success"><span>{{data.item.customer_type_name}}</span></b-badge>
              </div>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>

      <b-row v-else>
        <b-col><loading></loading></b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Loading from "@core/loading/Loading.vue";
import CustomerFilter from "./filters/CustomerFilter.vue";
import LocationFilter from "./filters/LocationFilter.vue";
import PromotionFilter from "./filters/PromotionFilter.vue";
import SalesOrderFilter from "./filters/SalesOrderFilter.vue";
import PriceCatalogFilter from "./filters/PriceCatalogFilter.vue";
import QuestionerFilter from "./filters/QuestionerFilter.vue";
import TargetFilter from "./filters/TargetFilter.vue";
import SelfRegistrationFilter from "./filters/SelfRegistrationFilter.vue";
import ProductFilter from "./filters/ProductFilter.vue";
import ContactFilter from "./filters/ContactFilter.vue";
import InvoiceFilter from "./filters/InvoiceFilter.vue";
import configFilter from "./configFilter.js";
import * as moment from "moment";
import { BBadge } from 'bootstrap-vue'

export default {
  name: "global-table",
  components: {
    Loading,
    CustomerFilter,
    LocationFilter,
    PromotionFilter,
    SalesOrderFilter,
    PriceCatalogFilter,
    ProductFilter,
    ContactFilter,
    QuestionerFilter,
    TargetFilter,
    SelfRegistrationFilter,
    InvoiceFilter,
    BBadge,
  },
  props: {
    striped: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: [Array, Object, Function],
      default: () => [],
    },
    fields: {
      type: [Array, Object],
      default: () => [],
    },
    dark: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    redirectDetail: {
      type: Boolean,
      default: true,
    },
    duplicateAdd: {
      type: Boolean,
      default: false,
    },
    editButton: {
      type: Boolean,
      default: true,
    },
    editButtonRegistration: {
      type: Boolean,
      default: false,
    },
    addEquipment: {
      type: Boolean,
      default: false,
    },
    previewButton: {
      type: Boolean,
      default: false,
    },
    removeButton: {
      type: Boolean,
      default: false,
    },
    activationButton: {
      type: Boolean,
      default: false,
    },
    resendActivationEmailButton: {
      type: Boolean,
      default: false,
    },
    downloadButton: {
      type: Boolean,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    showAddOnButton: {
      type: Boolean,
      default: false,
    },
    showOneTimeFeeCustomButton: {
      type: Boolean,
      default: false,
    },
    showImportButton: {
      type: Boolean,
      default: false,
    },
    showExportButton: {
      type: Boolean,
      default: false,
    },
    filterOptions: {
      type: Boolean,
      default: true,
    },
    addButton: {
      type: String,
      default: "Add Form",
    },
    importButton: {
      type: String,
      default: "Import Data",
    },
    exportButton: {
      type: String,
      default: "Export Data",
    },
    customVariableId: {
      type: String,
      default: "id",
    },
    idModals: {
      type: String,
      default: "addModals",
    },
    idModalsQuesioner: {
      type: String,
      default: "questionerModals",
    },
    idModalsAssignAo: {
      type: String,
      default: "customerAssignModals",
    },
    idModalsCancelRegis: {
      type: String,
      default: "customerCancelModals",
    },
    idModalsAddon: {
      type: String,
      default: "addOnModalForm",
    },
    idModalsOneTimeFeeCustom: {
      type: String,
      default: "oneTimeFeeCustomModalForm",
    },
    filterOps: {
      type: String,
      default: "",
    },
    totalRows: {
      type: Number,
      default: 1,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    editTermination: {
      type: Boolean,
      default: false,
    },
    historyLogButton: {
      type: Boolean,
      default: false,
    },
    // editReadyness: {
    //   type: Boolean,
    //   default: false,
    // },
    downloadInvoiceButton: {
      type: Boolean,
      default: false,
    },
    infoSelection: {
      type: Number,
      default: 0,
    },
    updateReadynessAll: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      pageOptions: [5, 10, 20, 50, 100],
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: {},
      perPage: 10,
      newlength: 0,
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    getFilterOps() {
      return this.filterOps;
    },
  },
  mounted() {
    if (this.filterOps === "customer") {
      this.filter = configFilter.customer;
    }else if (this.filterOps === "location") {
      this.filter = configFilter.location;
    }else if(this.filterOps === "promotion"){
      this.filter = configFilter.promotion;
    }else if(this.filterOps === "sales-order"){
      this.filter = configFilter.sales_order;
      this.filter.date = moment(Date.now()).format("YYYY-MM-DD");
    }else if(this.filterOps == "price-catalog"){
      this.filter = configFilter.price_catalog;
    }else if(this.filterOps == "product"){
      this.filter = configFilter.product
    }else if(this.filterOps == "contact"){
      this.filter = configFilter.contact
    }else if(this.filterOps == "questioner"){
      this.filter = configFilter.questioner
    }else if(this.filterOps == "target-scheme"){
      this.filter = configFilter.target
    }else if(this.filterOps == "self-registration"){
      this.filter = configFilter.self_registration
    }
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // console.log(filteredItems);
      this.newlength = filteredItems.length;
      this.currentPage = 1;
    },
    forceRefresh: function(){
      this.$refs.myTable.refresh();
    },
    addForm: function (button) {
      this.$emit("isEdit", false);
      this.$emit("resetForm");
      this.$root.$emit("bv::show::modal", this.idModals, button);
    },
    addOnForm: function (button) {
      this.$emit("isEdit", false);
      this.$emit("resetForm");
      this.$root.$emit("bv::show::modal", this.idModalsAddon, button);
    },
    addOneTimeFeeForm: function (button) {
      this.$emit("isEdit", false);
      this.$emit("resetForm");
      this.$root.$emit("bv::show::modal", this.idModalsOneTimeFeeCustom, button);
    },
    getQuesioner: function(row){
      this.$root.$emit("bv::show::modal", this.idModalsQuesioner);
      this.$emit("getQuesioner", row);
    },
    customerAssign: function(row){
      this.$emit("getCustomer", row);
      this.$root.$emit("bv::show::modal", this.idModalsAssignAo);
    },
    customerCancelSelfRegis: function(row){
      this.$emit("getCancelCustomer", row);
      this.$root.$emit("bv::show::modal", this.idModalsCancelRegis);
    },
    importForm: function (button) {
      this.$emit("importForm");
      //this.$root.$emit("bv::show::modal", this.idModals, button);
    },
    exportForm: function (button) {
      this.$emit("exportForm");
      //this.$root.$emit("bv::show::modal", this.idModals, button);
    },
    activationCustomer: function(row){
      this.$emit("activationCustomer", row);
    },
    resendActivationEmailSalesOrder: function(row){
      this.$emit("resendActivationEmailSalesOrder", row);
    },
    removeData: function (row) {
      this.$emit("removeData", row);
    },
    removeChargeLog: function (row) {
      this.$emit("removeChargeLog", row);
    },
    downloadData: function (row) {
      this.$emit("downloadData", row);
    },
    goToDetail: function (row) {
      let path = this.$route.path.split("/");
      this.$router.push({
        path: path[2] + "/detail/" + row[this.customVariableId],
      });
    },
    goToFab: function (row) {
      let path = this.$route.path.split("/");
      this.$router.push({
        path: path[2] + "/detail/" + row[this.customVariableId],
      });
    },
    goToDuplicate: function (row) {
      this.$emit("editForm", row);
      this.$emit("isEdit", true);
      this.$root.$emit("bv::show::modal", this.idModals);
    },
    editForm: function (row) {
      this.$emit("editForm", row);
      this.$emit("isEdit", true);
      this.$root.$emit("bv::show::modal", this.idModals);
    },
    addmoreEquipment: function (row) {
      this.$emit("addEquipment", row);
      this.$emit("isEdit", true);
    },
    previewForm: function (row) {
      this.$emit("previewForm", row);
      this.$emit("isEdit", true);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateTimeFormat: function (date) {
      if (date) {
        return moment(date).format("DD MMM YYYY HH:mm:ss");
      }
    },
    dateTimeContractFormat: function (date) {
      if (date) {
        return moment(date).format("DD MMM YYYY HH:mm");
      }
    },
    formatDateHumanRead: function (date) {
      if (date) {
        return moment(date).format("DD MMM YYYY");
      }
    },
    formatPrice(value) {
      if (value === null || value == null || value == "" || value === "") {
        return "";
      } else {
        let val = (value / 1).toFixed(0).replace(".", ",");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    },
    formatPercentage(value) {
      if (value === null || value == null || value == "" || value === "") {
        return "";
      } else {
        let val = value + "%";
        return val;
      }
    },
    editmoreTermination: function (row) {
      this.$emit("editTermination", row);
      this.$emit("isEdit", true);
    },
    editReadyness: function (row) {
      this.$emit("editReadyness", row);
    },
    getDataLog: function (row) {
      this.$emit("getDataLog", row);
    },
    downloadInvoice: function(row){
      this.$emit("downloadInvoice", row);
    },
    selectAllRows(args) {
      this.$emit("selectAllRows", args, this.$refs.myTable);
    },
    onRowSelected: function(row){
      this.$emit("onRowSelected", row);
    },
    updateAllSelectedReady: function(){
      this.$emit("updateAllSelectedReady");
    },
    updateAllSelectedNotReady: function(){
      this.$emit("updateAllSelectedNotReady");
    }
  },
};
</script>

<style>
  .table-responsive {
    margin-bottom: -2rem;
  }
  .borderless td, .borderless th {
    border: none;
  }
</style>