<template>
  <b-card v-if="!loads">
    <b-row>
      <b-col md="3">
        <b-form-group label="Status">
          <model-select :options="dropdown_filter_status" v-model="filter.status" placeholder="-- Please select --"></model-select>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Customer Area">
            <model-select @input="get_customer_group" :options="dropdown_customer_area" v-model="filter.code_area" placeholder="-- Please select --"></model-select>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Customer Group">
            <model-select @input="get_customer_type" :options="dropdown_customer_group_type" v-model="filter.code_group" placeholder="-- Please select --"></model-select>
        </b-form-group>
      </b-col>
    </b-row>  
    <b-row>  
      <b-col md="3">
        <b-form-group label="Customer Type">
            <model-select @input="get_location" :options="dropdown_customer_type" v-model="filter.code_type" placeholder="-- Please select --"></model-select>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Location">
            <model-select :options="dropdown_location" v-model="filter.location" placeholder="-- Please select --"></model-select>
        </b-form-group>
      </b-col>  
    </b-row>
    <b-row>
      <b-col md="3">
        <b-button type="button" size="sm" variant="danger" @click="resetFilter"
          ><i class="fa fa-undo"></i> Clear</b-button
        >
      </b-col>
    </b-row>
  </b-card>

  <b-card v-else>
    <loading></loading>
  </b-card>
</template>

<script>
import Loading from "@core/loading/Loading.vue";
import axios from "axios";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "self-registration-filter",
  components: {
    Loading,
    flatPickr,
  },
  data() {
    return {
      dropdown_filter_status: [
        {"text":"Register by Sales","value":"2"},
        {"text":"Self Register","value":"3"}
      ],
      dropdown_customer_area: [],
      dropdown_customer_group_type: [],
      dropdown_customer_type: [],
      dropdown_location: []
    };
  },
  props: {
    filter: {
      type: [Array, Object],
      default: () => {},
    },
    loads: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    init: function () {
        this.resetFilter();
        this.get_customer_area();
    },
    resetFilter: function () {
      this.filter.status = null;
      this.filter.code_area = null;
      this.filter.code_group = null;
      this.filter.code_type = null;
      this.filter.location = null;
      this.$root.$emit('bv::refresh::table', 'myTable');
    },
    get_customer_area: function () {
      axios
        .get("self_registration/dropdown/customer-area/")
        .then((response) => {
          this.dropdown_customer_area = response.data.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    get_customer_group: function () {
      this.dropdown_location = [];
      this.filter.location = null;
      this.dropdown_customer_type = [];
      this.filter.code_type = null;
      this.filter.code_group = null;
      axios.get("self_registration/dropdown/customer-group-type/" + this.filter.code_area).then((response) => {
        this.dropdown_customer_group_type = response.data.data;
      });
    },
    get_customer_type: function () {
      this.dropdown_location = [];
      this.filter.location = null;
      this.dropdown_customer_type = [];
      this.filter.code_type = null;
      axios.get("self_registration/dropdown/customer-type/" + this.filter.code_area + "/" + this.filter.code_group).then((response) => {
        this.dropdown_customer_type = response.data.data;
      });
    },
    get_location: function () {
      this.dropdown_location = [];
      this.filter.location = null;
      axios.get("dropdown/location/" + this.filter.code_area + "/" + this.filter.code_group + "/" + this.filter.code_type).then((response) => {
          this.dropdown_location = response.data.location;
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>