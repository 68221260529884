<template>
  <b-card v-if="!loads">
    <b-row>
      <b-col md="3">
        <b-form-group label="Customer Area">
          <model-select @input="get_filter_customer_group" :options="dropdown_filter_code_area" v-model="filter.code_area" placeholder="-- Please select --"></model-select>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Customer Group">
          <model-select @input="get_filter_customer_type" :options="dropdown_filter_code_group" v-model="filter.code_group" placeholder="-- Please select --"></model-select>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Customer Type">
          <model-select :options="dropdown_filter_code_type" v-model="filter.code_type" placeholder="-- Please select --"></model-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-button type="button" size="sm" variant="danger" @click="resetFilter"
          ><i class="fa fa-undo"></i> Clear</b-button
        >
      </b-col>
    </b-row>
  </b-card>

  <b-card v-else>
    <loading></loading>
  </b-card>
</template>

<script>
import Loading from "@core/loading/Loading.vue";
import axios from "axios";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "price-catalog-filter",
  components: {
    Loading,
    flatPickr,
  },
  data() {
    return {
      dropdown_filter_code_area: [],
      dropdown_filter_code_group: [],
      dropdown_filter_code_type: [],
    };
  },
  props: {
    filter: {
      type: [Array, Object],
      default: () => {},
    },
    loads: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    init: function () {
          this.resetFilter();
          this.getDropdown();
    },
    getDropdown: function () {
      axios.get("master/product/dropdown").then((response) => {
        this.dropdown_filter_code_area = response.data.customer_area;
      });
    },
    get_filter_customer_group: function () {
      this.filter.code_group = null;
      this.filter.code_type = null;
      this.dropdown_filter_code_group = [];
      this.dropdown_filter_code_type = [];
      axios
        .get("dropdown/customer-group-type")
        .then((response) => {
          this.dropdown_filter_code_group = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {});
    },
    get_filter_customer_type: function () {
      axios
        .get("dropdown/customer-type/" + this.filter.code_group)
        .then((response) => {
          this.dropdown_filter_code_type = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {});
    },
    resetFilter: function () {
      this.filter.code_group = null;
      this.filter.code_type = null;
      this.filter.code_area = null;
      this.dropdown_filter_code_group = [];
      this.dropdown_filter_code_type = [];
      this.$root.$emit('bv::refresh::table', 'myTable');
    },
  },
  mounted() {
    this.init();
  },
};
</script>