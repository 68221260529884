<template>
  <b-card v-if="!loads">
    <b-row>
      <b-col md="3">
        <b-form-group label="Product Category">
          <model-select :options="dropdown_product_category" v-model="filter.product_category" placeholder="-- Please select --"></model-select>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Product Type">
          <model-select :options="dropdown_product_type" v-model="filter.product_type" placeholder="-- Please select --"></model-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="3">
        <b-button type="button" size="sm" variant="danger" @click="resetFilter"><i class="fa fa-undo"></i> Clear</b-button>
      </b-col>
    </b-row>
  </b-card>

  <b-card v-else>
    <loading></loading>
  </b-card>
</template>

<script>
import Loading from "@core/loading/Loading.vue";
import axios from "axios";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "product-filter",
  components: {
    Loading,
    flatPickr,
  },
  data() {
    return {
      dropdown_product_category: [],
      dropdown_product_type: [],
    };
  },
  props: {
    filter: {
      type: [Array, Object],
      default: () => {},
    },
    loads: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    init: function () {
      this.getDropdown();
    },
    getDropdown: function () {
      axios.get("master/product/dropdown").then((response) => {
        this.dropdown_product_category = response.data.product_category;
        this.dropdown_product_type = response.data.product_type;
      });
    },
    resetFilter: function () {
      this.filter.product_category = null;
      this.filter.product_type = null;
      this.$root.$emit('bv::refresh::table', 'myTable');
    },
  },
  mounted() {
    this.init();
  },
};
</script>