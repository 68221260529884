<template>
  <b-card v-if="!loads">
    <b-row>
      <b-col md="3">
        <b-form-group label="Year">
          <model-select :options="dropdown_year" v-model="filter.year" placeholder="-- Please select --"></model-select>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Month">
          <model-select :options="dropdown_month" v-model="filter.month" placeholder="-- Please select --"></model-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="3">
        <b-button type="button" size="sm" variant="danger" @click="resetFilter"><i class="fa fa-undo"></i> Clear</b-button>
      </b-col>
    </b-row>
  </b-card>

  <b-card v-else>
    <loading></loading>
  </b-card>
</template>

<script>
import Loading from "@core/loading/Loading.vue";
import axios from "axios";

export default {
  name: "location-filter",
  components: {
    Loading,
  },
  data() {
    return {
      dropdown_month: [],
      dropdown_year: [],
    };
  },
  props: {
    filter: {
      type: [Array, Object],
      default: () => {},
    },
    loads: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    init: function () {
      //this.getDropdown();
      this.getMonthYear();
    },
    getMonthYear: function(){
      this.isLoading = true;
      axios.get("dropdown/yearMonth").then((response) => {
        // assign value dropdown
        this.dropdown_year = response.data.dropdown_year_list;
        this.dropdown_month = response.data.dropdown_month_list;
      });
      this.isLoading = false;
    },
    resetFilter: function () {
      this.filter.month = null;
      this.filter.year = null;
      this.dropdown_year = [];
      this.dropdown_month = [];
      this.$root.$emit('bv::refresh::table', 'myTable');
    },
  },
  mounted() {
    this.init();
  },
};
</script>