export default {
    customer: {
        search: null,
        area: null,
        group: null,
        type: null,
        date_inserted: null,
    },
    location: {
        search: null,
        group_area: null,
		code_area: null,
		code_group: null,
		code_type: null,
    },
    promotion: {
        search: null,
        promotion_type: null,
		promotion_status: null,
        code_area: null,
        code_group: null,
        code_type: null
    },
    sales_order: {
        search: null,
        sales_status: null,
        date: null,
        code_area: null,
    },
    price_catalog: {
        search: null,
        code_area: null,
        code_group: null,
        code_type: null,
    },
    questioner: {
        search: null,
        show_location: null,
    },
    target: {
        search: null,
        area: null,
        role: null,
        year: null
    },
    self_registration: {
        search: null,
        status: null,
        code_area: null,
        code_group: null,
        code_type: null,
        location: null,
    },
    product:{
        product_category: null,
        product_type: null,

    },
    contact:{
        customer_code: null,
        customer_name: null,
    },
    // chargelog: {
    //     search: null,
    //     start_date: null,
    // },
}